import React from 'react';
import { Link } from 'gatsby';

import Page from '../components/core/page';
import Opener from '../components/common/opener';
import Closer from '../components/common/closer';
import WorkCard from '../components/pages/work/work-card';

import blue from '../images/work/blue.jpg';
import red from '../images/work/red.jpg';
import grey from '../images/work/grey.jpg';
import peach from '../images/work/peach.jpg';

import cans from '../images/work/cans.png';
import dog from '../images/work/dog.png';
import talk from '../images/work/talk.png';
import can from '../images/work/can.png';

import c1 from '../images/work/cyder-1.jpg';
import c2 from '../images/work/cyder-2.jpg';
import d1 from '../images/work/dog-1.jpg';
import d2 from '../images/work/dog-2.jpg';
import t1 from '../images/work/talk-1.jpg';
import t2 from '../images/work/talk-2.jpg';
import l1 from '../images/work/lb-2.jpg';
import l2 from '../images/work/lil-bud-1.jpg';

export default () => (
	<Page
		title="Work"
		desc="At 5fold, we don't have many rules or policies, but we do have one mandate: don't put anything out into the world unless you're proud of it. Take a look!"
	>
		<Opener title="work" subtitle="WHAT WE DO BEST.">
			<p>
				At 5fold, we don't have many rules or policies, but we do have one
				mandate; Don't put anything out into the world unless you're proud of
				it.
			</p>
		</Opener>
		<section>
			<WorkCard bg={blue} one={c1} oneAlt={'Two cans of Duntroon Cyder'} two={c2} twoAlt={'a crate of apples on the ground in an orchard'} layer={cans} layerAlt={'Two cans of Duntroon Standing Rock Cyder'}>
				<h3>DUNTROON CYDER HOUSE</h3>
				<p>
					Duntroon Cyder House is a new brand in the growing hard cider market -
					an industry experiencing explosive growth in recent years. In order to
					break through the competitive noise and stand out on the shelves,
					Duntroon asked us to help them craft a modern, premium brand that
					would showcase the company’s Celtic roots and stand the test of time.
				</p>
				<h4>WHAT WE DID:</h4>
				<p className="did-list">
					VISUAL IDENTITY, BRAND GUIDELINES, PACKAGE DESIGN, COLLATERAL DESIGN
				</p>
			</WorkCard>
			<WorkCard bg={red} one={d1} oneAlt={'An ad created by 5Fold for Ontario SPCA in situ'} two={d2} twoAlt={'Example of iAdopt website displayed on a smartphone'} layer={dog} layerAlt={'An example ad created by 5Fold for Ontario SPCA for their iAdopt campaign'} odd>
				<h3>iADOPT</h3>
				<p>
					The Ontario SPCA came to us looking to refresh their holiday adoption
					campaign. Our approach was to create a holiday gift guide showing all
					the amazing ways pets can enrich our lives all year long. In the end,
					the Ontario SPCA and their affiliates were able to find new homes for
					over 3,600 pets in need across the province.
				</p>
				<h4>WHAT WE DID:</h4>
				<p className="did-list">
					CAMPAIGN CONCEPT, COPYWRITING, ART DIRECTION, WEBSITE, PRINT ADS,
					DIGITAL ADS, SOCIAL MEDIA ELEMENTS
				</p>
			</WorkCard>
			<WorkCard bg={grey} one={t1} oneAlt={'The CMHA Start Talking website displayed on a desktop computer'} two={t2} twoAlt={'The CMHA Start Talking website displayed on multiple smartphones'} layer={talk} layerAlt={'An illustration of three individuals holding a giant cheque'}>
				<h3>CMHA • START TALKING</h3>
				<p>
					Mental health is a cause that’s close to our hearts, so when the CMHA
					approached us to help bring their Start Talking campaign to life on
					the web, we jumped at the chance. The reality is, most people will not
					seek out support or treatment for mental illness because of the
					stigma, so the best thing we can all do is start talking.
				</p>
				<h4>WHAT WE DID:</h4>
				<p className="did-list">
					ART DIRECTION, GRAPHIC DESIGN, WEBSITE DESIGN, WEBSITE DEVELOPMENT{' '}
				</p>
			</WorkCard>
			<WorkCard bg={peach} one={l1} oneAlt={'Three example cans of Little Buddha beverages on a blue background'} two={l2} twoAlt={'An example of the Little Buddha branding as created by 5Fold'} layer={can} layerAlt={'An example can of Little Buddha cocktail as designed by 5Fold'} odd>
				<h3>LITTLE BUDDHA</h3>
				<p>
					Little Buddha Cocktail Co. enlisted us to help them bring the first
					fully organic ready-to-drink cocktail to the Ontario market. We built
					the visual identity around a series of hand-illustrated “mandalas” to
					bring their unique use of organic, locally-sourced ingredients and
					flavour combinations to the forefront of the brand, the packaging, and
					the website.
				</p>
				<h4>WHAT WE DID:</h4>
				<p className="did-list">
					VISUAL IDENTITY, PACKAGING, ILLUSTRATION, WEBSITE, COLLATERAL
					ELEMENTS, MESSAGING AND BRAND STORY
				</p>
			</WorkCard>
		</section>
		<Closer>
			<h2>
				Like what you see?
				<br />
				<Link to="/team">Meet the team</Link> behind the work.
			</h2>
		</Closer>
	</Page>
);
