import React, { useRef } from 'react';
import StyledWorkCard from './style';
import gsap from 'gsap';
import VisibilitySensor from 'react-visibility-sensor';

export default ({
	bg,
	one,
	oneAlt,
	two,
	twoAlt,
	children,
	layer,
	layerAlt,
	odd,
	overlayWidth,
}) => {
	const inner = useRef(null);
	const layerRef = useRef(null);
	const onVisibilityChange = (isVisible) => {
		if (isVisible) {
			gsap.to(inner.current, 1, {
				opacity: 1,
				y: 0,
			});
			gsap.to(layerRef.current, 1, {
				y: 0,
				opacity: 1,
				scaleX: 1,
				scaleY: 1,
				delay: 0.3,
			});
		} else {
			gsap.to(inner.current, 1, {
				opacity: 0,
				y: 25,
			});
			gsap.to(layerRef.current, 1, {
				scaleX: 0.95,
				scaleY: 0.95,
				y: 25,
				opacity: 0,
			});
		}
	};
	return (
		<StyledWorkCard
			bg={bg}
			one={one}
			two={two}
			odd={odd}
			overlayWidth={overlayWidth}
		>
			<VisibilitySensor onChange={onVisibilityChange} partialVisibility>
				<div
					ref={inner}
					style={{
						opacity: 0,
						transform: 'translate(0px, 25px)',
					}}
				>
					<div className="main">
						{!odd && <div className="body">{children}</div>}
						<div className="imgWrap">
							<img src={layer} alt={layerAlt} ref={layerRef} />
						</div>
						{odd && <div className="body">{children}</div>}
					</div>
					<div className="showcase">
						<div>
							<img src={one} alt={oneAlt} />
						</div>
						<div>
							<img src={two} alt={twoAlt} />
						</div>
					</div>
				</div>
			</VisibilitySensor>
		</StyledWorkCard>
	);
};
