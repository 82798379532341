import styled from 'styled-components';

export default styled.div`
	max-width: 64rem;
	margin: 0 auto;
	margin-bottom: 8rem;
	@media ${(p) => p.theme.device.mobile} {
		margin-bottom: 4rem;
	}
	position: relative;

	&::before {
		background-color: ${(p) => p.theme.color.ghostDarken};
		position: absolute;
		width: 100%;
		height: 50rem;
		top: 20rem;
		content: '';
		&:nth-child(even) {
			left: 20rem;
		}
	}
	&:nth-child(even) {
		&::before {
			left: -10rem;
		}
	}
	&:nth-child(odd) {
		&::before {
			right: -10rem;
		}
	}
	&:first-child {
		margin-top: 2rem;
	}
	&:last-child {
		margin-bottom: 0;
		&::before {
			display: none;
		}
	}
	.main {
		display: flex;
		height: 450px;
		background-image: url(${(p) => p.bg});
		background-size: cover;
		background-position: center center;
		position: relative;
		z-index: 100;
		.imgWrap {
			display: flex;
			/* flex: 0 0 50%; */
			align-items: center;
			padding: 0 4rem;
			img {
				width: ${(p) => (p.overlayWidth ? p.overlayWidth : '100%')};
			}
			@media ${(p) => p.theme.device.mobile} {
				margin-top: -2rem;
			}
		}
		.body {
			color: white;
			padding: 3rem 4rem;
			/* flex: 0 0 50%; */
			@media ${(p) => p.theme.device.mobile} {
				padding: 3rem 1rem;
			}
			h3 {
				font-family: ${(p) => p.theme.font.display};
				font-size: 2rem;
				margin-bottom: 2rem;
			}
			h4 {
				font-family: ${(p) => p.theme.font.display};
				font-size: 1.25rem;
				margin-top: 2rem;
			}
			.did-list {
				font-family: ${(p) => p.theme.font.display};
				font-size: 1rem;
			}
		}
		@media ${(p) => p.theme.device.mobile} {
			flex-direction: ${(p) => (p.odd ? 'column' : 'column-reverse')};
			height: unset;
		}
		& > div {
			flex: 1 1;
		}
	}
	.showcase {
		font-size: 0;
		div {
			display: inline-block;
			width: 50%;
			@media ${(p) => p.theme.device.mobile} {
				width: 100%;
			}
		}
		img {
			width: 100%;
		}
	}
`;
